import { EnvironmentId } from "../app/core/enums/environment-id.enum";
import { Environment } from "../app/core/models/environment.model";

export const environment: Environment = {
  id: EnvironmentId.Development,
  apiBaseUrl: "https://bff.dev.sibase-x.siemens.cloud/api",
  auth: {
    clientId: "942b6f79-4963-4d2f-aa93-c4cc72db5fc2",
    authority: "https://login.microsoftonline.com/38ae3bcd-9579-4fd4-adda-b42e1495d55a/",
    scopes: "api://ec9227f3-40fc-4f0a-8bd9-91c0658735be.siemens.com/API.Read",
  },
};
