import { DOCUMENT } from "@angular/common";
import { Component, DestroyRef, Inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, NavigationError, Router, RouterOutlet } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { EventMessage, EventType as MsalEventType } from "@azure/msal-browser";
import { TranslateService } from "@ngx-translate/core";
import { NgHttpLoaderComponent } from "ng-http-loader";
import { filter, startWith } from "rxjs";
import { environment } from "src/environments/environment";
import { SpinnerLargeComponent } from "./shared/components/spinner-large/spinner-large.component";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [RouterOutlet, NgHttpLoaderComponent],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit {
  /** spinner used by api loading overlay */
  public readonly spinner = SpinnerLargeComponent;

  /** Api urls that don't trigger the loading overlay */
  readonly filteredUrlPatterns: string[] = [
    "/FacilityHierarchy?",
    "/IBaseHierarchy/GetIBaseHierarchy?",
    "/IBaseHierarchy/GetIBaseHierarchyDescendantsByParentIBaseId?",
  ];

  constructor(
    protected readonly destroyRef: DestroyRef,
    protected readonly msal: MsalService,
    protected readonly msalBroadcast: MsalBroadcastService,
    protected readonly translate: TranslateService,
    protected readonly route: ActivatedRoute,
    protected readonly router: Router,
    @Inject(DOCUMENT) protected readonly document: Document
  ) {}

  ngOnInit(): void {
    console.log("Environment:", environment.id);

    this.translate.onLangChange.pipe(startWith(null), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.document.documentElement.lang = this.translate.currentLang;
    });

    // Ensures that there's something in the main router outlet after NavigationError
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationError),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(async (navigationError) => {
        if (!this.route.root.firstChild && navigationError.url && navigationError.url !== "/") {
          await this.router.navigate(["/"]);
        }
      });

    this.msalBroadcast.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === MsalEventType.LOGIN_SUCCESS ||
            msg.eventType === MsalEventType.LOGOUT_END ||
            msg.eventType === MsalEventType.ACCOUNT_ADDED ||
            msg.eventType === MsalEventType.ACCOUNT_REMOVED
        ),
        startWith(null),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        const allAccounts = this.msal.instance.getAllAccounts() ?? [];
        const previousActive = this.msal.instance.getActiveAccount();
        if (previousActive && !allAccounts.includes(previousActive)) {
          this.msal.instance.setActiveAccount(null);
        }
        if (!this.msal.instance.getActiveAccount() && allAccounts.length > 0) {
          this.msal.instance.setActiveAccount(allAccounts[0]);
        }
      });
  }
}
