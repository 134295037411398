import { msalCanActivateFn } from "./core/guards/msal.guard";
import { AppRoutes } from "./core/models/app-route.model";
import { MainDashboardComponent } from "./main-dashboard/main-dashboard.component";

export const appRoutes: AppRoutes = [
  {
    path: "",
    title: "SIBase X",
    loadComponent: () => import("./core/layouts/main-layout/main-layout.component").then((c) => c.MainLayoutComponent),
    canActivate: [msalCanActivateFn],
    pathMatch: "prefix",
    loadChildren: async (): Promise<AppRoutes> => [
      {
        path: "i-base-management",
        loadChildren: () => import("./i-base-management/i-base-management.routes").then((r) => r.iBaseManagementRoutes),
      },
      {
        path: "facilities-and-contacts",
        loadChildren: () => import("./facilities-and-contacts/facilities-and-contacts.routes").then((r) => r.facilitiesAndContactsRoutes),
      },
      {
        path: "value-list-management",
        children: [],
      },
      {
        path: "",
        component: MainDashboardComponent,
      },
    ],
  },
  {
    path: "**",
    redirectTo: "/",
  },
];
