import { Component } from "@angular/core";
import { IxModule } from "@siemens/ix-angular";

@Component({
  selector: "app-spinner-large",
  standalone: true,
  imports: [IxModule],
  template: `<ix-spinner size="large" />`,
})
export class SpinnerLargeComponent {}
