<ix-layout-grid gap="24" [noMargin]="true">
  <ix-row>
    <ix-col sizeSm="12" sizeMd="6" sizeLg="4">
      <button role="link" routerLink="i-base-management" type="button" class="dashboard-card">
        <ix-icon class="dashboard-card__main-icon" name="assets/svg/i-base-management.svg" />
        <div class="dashboard-card__name-container">
          <h2 class="dashboard-card__name">{{ "MAIN_DASHBOARD.I_BASE_MANAGEMENT" | translate }}</h2>
          <h3 class="dashboard-card__description"> </h3>
        </div>
        <div class="dashboard-card__arrow-icon-container">
          <ix-icon class="dashboard-card__arrow-icon" name="arrow-right" />
        </div>
      </button>
    </ix-col>

    <ix-col sizeSm="12" sizeMd="6" sizeLg="4">
      <button role="link" routerLink="facilities-and-contacts" type="button" class="dashboard-card">
        <ix-icon class="dashboard-card__main-icon" name="plant-user" />
        <div class="dashboard-card__name-container">
          <h2 class="dashboard-card__name">{{ "MAIN_DASHBOARD.FACILITIES_AND_CONTACTS" | translate }}</h2>
          <h3 class="dashboard-card__description"> </h3>
        </div>
        <div class="dashboard-card__arrow-icon-container">
          <ix-icon class="dashboard-card__arrow-icon" name="arrow-right" />
        </div>
      </button>
    </ix-col>

    <ix-col sizeSm="12" sizeMd="6" sizeLg="4">
      <button role="link" routerLink="value-list-management" type="button" class="dashboard-card">
        <ix-icon class="dashboard-card__main-icon" name="assets/svg/value-list-management.svg" />
        <div class="dashboard-card__name-container">
          <h2 class="dashboard-card__name">{{ "MAIN_DASHBOARD.VALUE_LIST_MANAGEMENT" | translate }}</h2>
          <h3 class="dashboard-card__description"> </h3>
        </div>
        <div class="dashboard-card__arrow-icon-container">
          <ix-icon class="dashboard-card__arrow-icon" name="arrow-right" />
        </div>
      </button>
    </ix-col>
  </ix-row>
</ix-layout-grid>
