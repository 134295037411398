import { b as bootstrapLazy } from './index-c455afb1.js';
export { s as setNonce } from './index-c455afb1.js';
import { g as globalScripts } from './app-globals-0f993ce5.js';
const defineCustomElements = async (win, options) => {
  if (typeof window === 'undefined') return undefined;
  await globalScripts();
  return bootstrapLazy([["ix-siemens-about-content", [[1, "ix-siemens-about-content", {
    "applicationName": [1, "application-name"],
    "applicationDescription": [1, "application-description"],
    "imprintLanguage": [1, "imprint-language"],
    "copyrightYears": [1, "copyright-years"],
    "i18nCorporateLabel": [1, "i-1-8n-corporate-label"],
    "i18nPrivacyNoticeLabel": [1, "i-1-8n-privacy-notice-label"],
    "i18nTermsOfUseLabel": [1, "i-1-8n-terms-of-use-label"],
    "i18nCookieNoticeLabel": [1, "i-1-8n-cookie-notice-label"],
    "i18nDigitalId": [1, "i-1-8n-digital-id"]
  }]]], ["ix-siemens-logo", [[1, "ix-siemens-logo"]]]], options);
};
export { defineCustomElements };

