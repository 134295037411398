import { Component } from "@angular/core";
import { RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { IxModule } from "@siemens/ix-angular";

@Component({
  selector: "app-main-dashboard",
  templateUrl: "./main-dashboard.component.html",
  styleUrls: ["./main-dashboard.component.scss"],
  imports: [RouterLink, IxModule, TranslateModule],
  standalone: true,
})
export class MainDashboardComponent {}
