<ng-http-loader
  opacity="0.7"
  backdropBackgroundColor="var(--theme-color-2)"
  [entryComponent]="spinner"
  [debounceDelay]="100"
  [minDuration]="300"
  [backdrop]="true"
  [filteredUrlPatterns]="filteredUrlPatterns" />

<router-outlet />
