import { inject } from "@angular/core";
import { CanActivateChildFn, CanActivateFn, CanMatchFn } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";

// Wrap deprecated guard class format into new guard functions format
// Use their guard functions if Microsoft provide them in the future

export const msalCanActivateFn: CanActivateFn = (...args) => inject(MsalGuard).canActivate(...args);

export const msalCanActivateChildFn: CanActivateChildFn = (...args) => inject(MsalGuard).canActivateChild(...args);

export const msalCanMatchFn: CanMatchFn = () => inject(MsalGuard).canMatch();
